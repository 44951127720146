.dashboard-select-date-button{
    cursor: pointer;
    padding: 13px 11px;
    color:#68737E;
    background-color: white;
    border:  solid 1px  #68737E;
    opacity: 0.4;
    font-family: "Roboto Light";
    font-size: 16px;
    border-radius: 6px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    font-weight: 200;
    border: solid 1px rgba(104, 115, 126, 0.5);
    border-style: ridge;
    border-width: thin;
}

.dashboard-select-date-button-selected{
    cursor:pointer;
    padding: 13px 11px;
    color: white;
    background-color:#009DC8 ;
    font-family: "Roboto Light";
    font-size: 16px;
    border-radius: 6px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    font-weight: 200;
}