.company-mycertificate-rightgroup-row {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.company-mycertificate-rightgroup-container {
	display: flex;
	flex-direction: column;
	width: 100%;
}
