.homepage-container {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	padding-top: 51px;
	padding-left: 31px;
	padding-right: 31px;
	margin-top: -54px;
}

.homepage-top-section {
	width: 100%;
	height: 280px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.homepage-header-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.homepage-body-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.homepage-illustration-section {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row-reverse;
	align-items: flex-end;
}

.homepage-illustration-section img {
	width: 180px;
	height: 225px;
}

.homepage-illustration-text-bubble {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 10px;
	width: 258px;
	height: fit-content;
	background: #ffffff;
	border-radius: 20px;
}

.homepage-illustration-content {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	border-radius: 20px;
}

.user-greetings {
	width: 238px;

	/* BO/Default bold */

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 22px;
	/* or 157% */

	/* Medicheck/Grey/Dark */

	color: #405364;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;
}

.homepage-title {
	/* Title */

	width: 142px;
	height: 38px;

	/* BO/Title */

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 32px;
	line-height: 38px;
	/* identical to box height, or 119% */

	/* Medicheck/Grey/Dark */

	color: #405364;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;

	margin-bottom: 11px;
}

.homepage-subtitle {
	/* Sutitle */

	width: 256px;
	height: 19px;

	/* BO / Subtitle */

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	/* identical to box height, or 119% */

	/* Medicheck/Grey/Light */

	color: #90a7b8;

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;
}

.homepage-date {
	height: 44px;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 44px;
	/* identical to box height, or 122% */

	/* Medicheck/Grey/Light */

	color: #90a7b8;

	/* Inside auto layout */

	flex: none;
	order: 0;
	flex-grow: 0;

	margin-bottom: 25px;
}

.homepage-week {
	height: 44px;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 44px;
	/* identical to box height, or 122% */

	/* Medicheck/Grey/Light */

	color: #90a7b8;

	/* Inside auto layout */

	flex: none;
	order: 1;
	flex-grow: 0;
}
