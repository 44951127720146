.dashboard-group-container{
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,0.2);
    padding: 45px 49px;
    display: flex;
    flex-direction: row;
    width: fit-content;
    margin-top: 30px
}

.dashboard-group-container:first-child{
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,0.2);
    padding: 45px 49px;
    display: flex;
    flex-direction: row;
    width: fit-content;
}
.dashboard-group-container:nth-child(2){
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 3px 10px 0 rgba(0,0,0,0.2);
    padding: 45px 49px;
    display: flex;
    flex-direction: row;
    width: fit-content;
}