.settings-detail-input-triple {
	margin: 10px;
	margin-left: auto;
	margin-right: auto;
}

.settings-detail-input-full {
	margin: 10px;
	margin-left: auto;
	margin-right: auto;
	width: 995px;
}

.settings-detail-second-row {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
}

.settings-detail-second-row-input {
	width: 100%;
}
