.company-certificate-upload-card-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 18px 22px;
}

.company-certificate-upload-button-end{
    display: flex;
    flex-direction: row;
}

.company-certificate-upload-button-title{
    color: #405364;
    font-family: Roboto;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
}

.company-certificate-upload-button-container{
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: #F5FAFF;
    border: 2px solid #90A7B8;
    padding: 6px 10px;
    color: #90A7B8;
    font-family: Roboto;
    font-size: 18px;
    cursor: pointer;
}

.company-certificate-upload-button-container:hover{
    display: flex;
    color: white;
    align-items: center;
    border-radius: 8px;
    background-color: #009DC8;
    padding: 6px 10px;
    font-size: 18px;
    border: 2px solid #009DC8;
    font-family: Roboto;
    cursor: pointer;
}


.company-certificate-upload-button-container:hover > .img-base-primary{
    filter: brightness(0) invert(1);
}