.container {
    margin-left: 10;
}

.peerMessagin-header {
    margin-bottom: 36px;
}

.peerMessagin-number-input {
    margin-bottom: 26px;
}

.peer-messaging-button-container {
    margin-top: 54px;
}