.settings-detail-header-container{
    display: flex;
    flex-direction: row;
    width: 1047px;

}

.settings-detail-header-delete-container{
    margin-left: auto;
    align-self: center;
}