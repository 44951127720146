.custom-btn{
  display: flex;
  background-color: #fff;
  padding: 15px 11px;
  border: 2px solid #90A7B8;
  border-radius: 8px;
  color: #90A7B8;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 22px;
  cursor: pointer;
}

.custom-btn-icon {
  margin-right: 20px;
}