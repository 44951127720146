.chat-container {
    overflow: scroll;
    scrollbar-color: transparent;
    background-color: rgb(245, 250, 255) !important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.0631283);
}

.chat-body {
    overflow-x: hidden;
    scrollbar-color: transparent;
    margin-bottom: 30px;
}

.chat-body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.chat-body {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    height: fill;
}

.chat-input-container {
    padding: 0px;
}

.chat-message-user {
    background: rgb(144, 167, 184);
    color: white;
    font-size: 12px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
}

.chat-message-user.invisible {
    opacity: 0;
}

.chat-message {
    margin-top: 15px;
    display: flex;
    align-items: flex-end;
    width: 100%;
    user-select: text;
}

.chat-message-body.right {
    background: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.0631283);
    border-radius: 22px 22px 5px 22px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    margin: 0px;
    margin-right: 10px;
    padding: 12px 20px;
    max-width: 70%;
    height: fit-content;
    line-break: anywhere;
    margin-left: auto;
    user-select: text;
}

.chat-message-body.left {
    background: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.0631283);
    border-radius: 22px 22px 22px 5px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    margin: 0px;
    margin-left: 10px;
    padding: 12px 20px;
    max-width: 70%;
    height: fit-content;
    line-break: anywhere;
    margin-right: auto;
    user-select: text;
}

.chat-button {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    min-width: 45px;
    height: 45px;
    color: white;
    cursor: pointer;
}

.chat-button img {
    height: 35px;
    width: 35px;
}

.chat-message-user {
    height: 25px;
    width: 25px;
    border-radius: 100%;
    margin-top: auto;
    user-select: text;
}

.chat-message {
    margin-top: 5px;
    display: flex;
    width: 100%;
    user-select: text;
}

.chatOverlayClickOutside {
    position: fixed;
    inset: 0;
    cursor: pointer;
    opacity: 50;
}