.dashboard-lateral-bar{
    width: 4px;
    border-radius: 20px;
}

.dashboard-card-numbers-container{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.dashboard-card-first-part{
    display: flex;
    flex-direction: row;
    width: fit-content;
}

.dashboard-card-total{
    color: #405364;
    font-family: Roboto;
    font-size: 26px;
    font-weight: 700;
    line-height: 30px;
}


.dashboard-card-empty{
    color: #405364;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    opacity: 0;
    margin-top: 6px;

}

.dashboard-card-metric{
    color: #405364;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    margin-top: 6px

}

.dashboard-card-title{
    color: #405364;
    font-family: "Roboto Light";
    font-size: 14px;
    line-height: 16px;
    margin-left: 14px;
    margin-top: 6px;
    font-weight: 200;
    max-width: fit-content;
}

.dashboard-card-container{
    margin-right: 47px;
    width: 97px;
}
.dashboard-card-container:last-child{
    margin-right: 0px;
}