.dashboard-filter-company-container{
    display: flex;
    flex-direction: row;
}

.dashboard-select-all-button{
    padding: 13px 11px;
    background-color: white;
    font-family: "Roboto Light";
    font-size: 16px;
    line-height: 19px;
    opacity: 0.4;
    color: #68737E;
    font-family: "Roboto Light";
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    border-radius: 6px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    font-weight: 200;
    border: solid 1px rgba(104, 115, 126, 0.5);
    border-style: ridge;
    border-width: thin;
}

.dashboard-select-all-button-selected{
    padding: 13px 11px;
    color: white;
    font-family: "Roboto Light";
    font-size: 16px;
    line-height: 19px;
    border-radius: 6px;
    background-color: #009DC8;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    display: flex;
    align-items: center;
    font-weight: 200;
}